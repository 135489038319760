import axios from 'axios';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Mail = () => {
    const [text, setText] = useState("");
    const [title, setTitle] = useState("");
    const [loading, setLoading] = useState(false);
    const [rec, setRec] = useState("All");


    const HandlSubmit = async () => {
        try {
            setLoading(true)
            if (rec === "All") {
                await axios.post(
                    process.env.REACT_APP_BASE_URL +
                    `/api/admin/sendall`,
                    {
                        text,
                        title,
                    },
                    { headers: { token: Cookies.get("token") } }
                );
            } else if (rec === "KYC") {
                await axios.post(
                    process.env.REACT_APP_BASE_URL +
                    `/api/admin/sendall`,
                    {
                        text,
                        title,
                        kyc: true
                    },
                    { headers: { token: Cookies.get("token") } }
                );
            } else if (rec === "NKYC") {
                await axios.post(
                    process.env.REACT_APP_BASE_URL +
                    `/api/admin/sendall`,
                    {
                        text,
                        title,
                        kyc: false
                    },
                    { headers: { token: Cookies.get("token") } }
                );
            }else if (rec === "datos") {
                await axios.post(
                    process.env.REACT_APP_BASE_URL +
                    `/api/admin/sendall`,
                    {
                        text,
                        title,
                        postalcode: false
                    },
                    { headers: { token: Cookies.get("token") } }
                );
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    }


    // useEffect(() => {
    //     console.log(rec);

    // }, [rec])

    return (
        <div>
            <h1 className='border-bottom mb-3 fw-bold fs-4 pb-2'>Send Mail to All Users</h1>
            <input type='text' placeholder='Subject' className='form-control mb-2' onChange={(e) => setTitle(e.target.value)} />
            <ReactQuill
                value={text}
                onChange={(e) => setText(e)}
                modules={{
                    toolbar: [
                        [{ 'header': [1, 2, false] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        // ['link', 'image'],
                        ['clean']
                    ],
                }}
                formats={[
                    'header',
                    'bold', 'italic', 'underline', 'strike',
                    'list', 'bullet',
                    // 'link', 'image',
                ]}
                placeholder="Type something..."
            />

            <label className='mt-2'>Receiver:</label>
            <select onChange={(e) => setRec(e.target.value)} className='form-control mt-1'>
                <option value={"All"}>Send to All</option>
                <option value={"NKYC"}>Send To Non KYC</option>
                <option value={"KYC"}>Send To KYC</option>
                <option value={"datos"}>Send To Datos Not Completed</option>
            </select>
            <button className='btn btn-primary mt-3' onClick={HandlSubmit}>{loading ? "Loading" : "Send"}</button>

        </div>
    )
}

export default Mail